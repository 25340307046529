var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-simple-table", {
        attrs: { dense: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "tbody",
                  _vm._l(
                    _vm.itemAttributes.filter((c) => c.level <= 1),
                    function (col) {
                      return _c("tr", { key: col.value }, [
                        _c("th", { staticStyle: { width: "25%" } }, [
                          _vm._v(_vm._s(col.text)),
                        ]),
                        _c(
                          "td",
                          { attrs: { colspan: _vm.serviceRates.length } },
                          [
                            col.value == "months_order"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.selectedTableItem[col.value].toFixed(
                                        1
                                      )
                                    )
                                  ),
                                ])
                              : col.value == "months_stock"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.selectedTableItem[col.value].toFixed(
                                        1
                                      )
                                    )
                                  ),
                                ])
                              : col.value == "price"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.selectedTableItem[col.value].toFixed(
                                        3
                                      )
                                    )
                                  ),
                                ])
                              : col.value == "order_price"
                              ? _c("span", [
                                  _vm._v(
                                    "\\" +
                                      _vm._s(
                                        _vm.selectedTableItem[
                                          col.value
                                        ].toLocaleString()
                                      )
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.selectedTableItem[col.value])
                                  ),
                                ]),
                          ]
                        ),
                      ])
                    }
                  ),
                  0
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("v-simple-table", {
        attrs: { dense: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "tbody",
                  [
                    _c(
                      "tr",
                      [
                        _c("th", { staticStyle: { width: "25%" } }, [
                          _vm._v("需要統計情報"),
                        ]),
                        _vm._l([8, 16, 32, 64], function (w) {
                          return _c("th", { key: `header-stat-${w}` }, [
                            _vm._v("過去" + _vm._s(w) + "週"),
                          ])
                        }),
                      ],
                      2
                    ),
                    _vm._l(["df"], function (s) {
                      return _c(
                        "tr",
                        { key: `${s}` },
                        [
                          s == "df"
                            ? _c(
                                "th",
                                { staticStyle: { "text-align": "right" } },
                                [_vm._v("頻度 (P)")]
                              )
                            : _vm._e(),
                          _vm._l([8, 16, 32, 64], function (w) {
                            return _c("td", { key: `s${s}w${w}` }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedTableItem[
                                      `${s}_last${w}`
                                    ].toFixed(1)
                                  ) +
                                  " "
                              ),
                            ])
                          }),
                        ],
                        2
                      )
                    }),
                    _vm._l(["ave", "std"], function (s) {
                      return _c(
                        "tr",
                        { key: `${s}` },
                        [
                          s == "ave"
                            ? _c(
                                "th",
                                { staticStyle: { "text-align": "right" } },
                                [
                                  _vm._v(
                                    "平均 (" +
                                      _vm._s(_vm.selectedTableItem.order_unit) +
                                      ")"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          s == "std"
                            ? _c(
                                "th",
                                { staticStyle: { "text-align": "right" } },
                                [
                                  _vm._v(
                                    "標準偏差 (" +
                                      _vm._s(_vm.selectedTableItem.order_unit) +
                                      ")"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._l([8, 16, 32, 64], function (w) {
                            return _c("td", { key: `s${s}w${w}` }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (
                                      _vm.selectedTableItem[`${s}_last${w}`] /
                                      _vm.selectedTableItem.unit_qty
                                    ).toFixed(2)
                                  ) +
                                  " "
                              ),
                            ])
                          }),
                        ],
                        2
                      )
                    }),
                    _c("tr"),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("v-simple-table", {
        attrs: { dense: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("tbody", [
                  _c("tr", [
                    _c("th", { staticStyle: { width: "25%" } }, [
                      _vm._v("現在庫"),
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          (
                            _vm.selectedTableItem.stocks_all /
                            _vm.selectedTableItem.unit_qty
                          ).toFixed(2)
                        ) +
                          " " +
                          _vm._s(_vm.selectedTableItem.order_unit) +
                          " （バラ数：" +
                          _vm._s(
                            _vm.selectedTableItem.stocks +
                              _vm.selectedTableItem.remains
                          ) +
                          "）"
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", { staticStyle: { width: "25%" } }, [
                      _vm._v("発注点"),
                    ]),
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.selectedTableItem[
                              `order_point_fp${_vm.selectedTableItem.fp}_sr${_vm.selectedTableItem.sr}`
                            ]
                          ) +
                          " " +
                          _vm._s(_vm.selectedTableItem.order_unit) +
                          "（ " +
                          _vm._s(_vm.selectedTableItem.fp) +
                          "週後予測 / 累積事後確率" +
                          _vm._s(_vm.selectedTableItem.sr) +
                          " % ）"
                      ),
                    ]),
                  ]),
                  _c("tr"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("v-simple-table", {
        attrs: { dense: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "tbody",
                  [
                    _c(
                      "tr",
                      [
                        _c("th", { staticStyle: { width: "25%" } }, [
                          _vm._v("予測需要"),
                        ]),
                        _vm._l(_vm.forecastPeriods, function (fp) {
                          return _c("th", { key: `header-${fp}` }, [
                            _vm._v(_vm._s(fp) + "週後予測"),
                          ])
                        }),
                      ],
                      2
                    ),
                    _vm._l(_vm.serviceRates, function (sr) {
                      return _c(
                        "tr",
                        { key: `sr${sr}` },
                        [
                          _c("th", { staticStyle: { "text-align": "right" } }, [
                            _vm._v("累積事後確率 " + _vm._s(sr) + " %"),
                          ]),
                          _vm._l(_vm.forecastPeriods, function (fp) {
                            return _c("td", { key: `fp${fp}sr${sr}` }, [
                              _vm.selectedTableItem.fp_shp == fp ||
                              _vm.selectedTableItem.sr == sr
                                ? _c(
                                    "span",
                                    {
                                      style:
                                        "color:#1976d2;text-align:right" +
                                        (_vm.selectedTableItem.fp_shp == fp &&
                                        _vm.selectedTableItem.sr == sr
                                          ? "font-weight:bold;"
                                          : ""),
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.selectedTableItem[
                                              `order_point_fp${fp}_sr${sr}`
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.selectedTableItem[
                                              `order_point_fp${fp}_sr${sr}`
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                            ])
                          }),
                        ],
                        2
                      )
                    }),
                    "ml-gbdt-cls_fp1_pred" in _vm.selectedTableItem
                      ? _c(
                          "tr",
                          [
                            _c(
                              "th",
                              { staticStyle: { "text-align": "right" } },
                              [_vm._v("事後確率最大（事後確率）")]
                            ),
                            _vm._l(_vm.forecastPeriods, function (fp) {
                              return _c("td", { key: `gbdt-cls-fp${fp}` }, [
                                _vm.selectedTableItem.fp_shp == fp
                                  ? _c(
                                      "span",
                                      {
                                        style:
                                          "color:#1976d2;text-align:right" +
                                          (_vm.selectedTableItem.fp_shp == fp
                                            ? "font-weight:bold;"
                                            : ""),
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.selectedTableItem[
                                                `ml-gbdt-cls_fp${fp}_pred`
                                              ]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.selectedTableItem[
                                                `ml-gbdt-cls_fp${fp}_pred`
                                              ]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      " ( " +
                                        _vm._s(
                                          _vm.selectedTableItem[
                                            `ml-gbdt-cls_fp${fp}_posterior`
                                          ].toFixed(2)
                                        ) +
                                        " ) "
                                    ),
                                  ]
                                ),
                              ])
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    "ml-gbdt-reg_fp1_pred" in _vm.selectedTableItem
                      ? _c(
                          "tr",
                          [
                            _c(
                              "th",
                              { staticStyle: { "text-align": "right" } },
                              [_vm._v("GBDT回帰予測")]
                            ),
                            _vm._l(_vm.forecastPeriods, function (fp) {
                              return _c("td", { key: `gbdt-reg-fp${fp}` }, [
                                _vm.selectedTableItem.fp_shp == fp
                                  ? _c(
                                      "span",
                                      {
                                        style:
                                          "color:#1976d2;text-align:right" +
                                          (_vm.selectedTableItem.fp_shp == fp
                                            ? "font-weight:bold;"
                                            : ""),
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.selectedTableItem[
                                                `ml-gbdt-reg_fp${fp}_pred`
                                              ]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.selectedTableItem[
                                                `ml-gbdt-reg_fp${fp}_pred`
                                              ]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                              ])
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    _c("tr"),
                  ],
                  2
                ),
                "classical_fp1_sr60" in _vm.selectedTableItem
                  ? _c(
                      "tbody",
                      _vm._l(_vm.serviceRates, function (sr) {
                        return _c(
                          "tr",
                          { key: `classical-sr${sr}` },
                          [
                            _c(
                              "th",
                              { staticStyle: { "text-align": "right" } },
                              [_vm._v("古典的・安全率 " + _vm._s(sr) + " %")]
                            ),
                            _vm._l(_vm.forecastPeriods, function (fp) {
                              return _c(
                                "td",
                                { key: `classical-fp${fp}sr${sr}` },
                                [
                                  _vm.selectedTableItem.fp_shp == fp
                                    ? _c(
                                        "span",
                                        {
                                          style:
                                            "color:#1976d2;text-align:right" +
                                            (_vm.selectedTableItem.fp_shp == fp
                                              ? "font-weight:bold;"
                                              : ""),
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.selectedTableItem[
                                                  `classical_fp${fp}_sr${sr}`
                                                ]
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.selectedTableItem[
                                                  `classical_fp${fp}_sr${sr}`
                                                ]
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }