var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("iflb-table", {
        staticClass: "text-no-wrap",
        attrs: {
          headers: _vm.headers,
          "raw-items": _vm.rawItems,
          "data-table-props": {
            "fixed-header": true,
            "multi-sort": true,
            dense: true,
            "hide-default-header": true,
            height: 700,
            "item-key": "product_code",
            "footer-props": { itemsPerPageOptions: [10, 30, 50, 100, 300, -1] },
            "items-per-page": 50,
            loading: _vm.loading,
            "item-class": (item) => (_vm.hasEdit(item) ? "edited" : ""),
          },
          "data-table-slots": _vm.dataTableSlots,
          "header-props": {
            alertTypes: _vm.alertType,
            filterTypes: _vm.headerFilterTypes,
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.checked",
              fn: function ({ item }) {
                return [
                  _c("v-checkbox", {
                    staticClass: "py-0 my-0",
                    attrs: { "hide-details": "", color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.updateEditedTableItem(
                          {
                            item,
                            optionValue: { sr: item.sr, fp: item.fp_shp },
                            forceZero: item.do_not_order,
                          },
                          !item.checked
                        )
                      },
                    },
                    model: {
                      value: item.checked,
                      callback: function ($$v) {
                        _vm.$set(item, "checked", $$v)
                      },
                      expression: "item.checked",
                    },
                  }),
                ]
              },
            },
            {
              key: "item.editAction",
              fn: function ({ item }) {
                return [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: _vm.hasEdit(item) ? "warning" : "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.openEditDialog(item)
                        },
                      },
                    },
                    [_vm._v(" mdi-pencil ")]
                  ),
                ]
              },
            },
            {
              key: "item.alerts",
              fn: function ({ item }) {
                return _vm._l(item.alerts, function (alert) {
                  return _c(
                    "v-chip",
                    {
                      key: `${item.product_code}-alert-${_vm.alertType[alert].text}`,
                      attrs: {
                        dark: "",
                        small: "",
                        color: _vm.alertType[alert].color,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.alertType[alert].text) + " ")]
                  )
                })
              },
            },
            {
              key: "item.order_num",
              fn: function ({ item }) {
                return [
                  _vm.hasEdit(item)
                    ? _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.editedItemHTML(item, "order_num")
                          ),
                        },
                      })
                    : _c("div", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              color: "rgb(26 118 210)",
                            },
                          },
                          [_vm._v(_vm._s(item.order_num))]
                        ),
                      ]),
                ]
              },
            },
            {
              key: "item.order_unit",
              fn: function ({ item }) {
                return [
                  item.order_unit_orig
                    ? _c("span", [
                        _c("s", [_vm._v(_vm._s(item.order_unit_orig))]),
                        _c("br"),
                        _c("b", [_vm._v(_vm._s(item.order_unit))]),
                      ])
                    : _c("span", [_vm._v(" " + _vm._s(item.order_unit) + " ")]),
                ]
              },
            },
            {
              key: "item.price",
              fn: function ({ item }) {
                return [_vm._v(" \\" + _vm._s(item.price.toFixed(3)) + " ")]
              },
            },
            {
              key: "item.months_order",
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.months_order ? item.months_order.toFixed(1) : 0
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "item.months_stock",
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.months_stock ? item.months_stock.toFixed(1) : 0
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "item.sr",
              fn: function ({ item }) {
                return [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.editedItemHTML(item, "sr")),
                    },
                  }),
                ]
              },
            },
            {
              key: "item.fp",
              fn: function ({ item }) {
                return [
                  item.forecast_period_orig
                    ? _c("span", { staticStyle: { color: "#bbb" } }, [
                        _c("s", [_vm._v(_vm._s(item.forecast_period_orig))]),
                        _vm._v("  "),
                      ])
                    : _c("span"),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.editedItemHTML(item, "fp")),
                    },
                  }),
                ]
              },
            },
            {
              key: "item.fp_shp",
              fn: function ({ item }) {
                return [
                  item.forecast_period_ship_orig
                    ? _c("span", { staticStyle: { color: "#bbb" } }, [
                        _c("s", [
                          _vm._v(_vm._s(item.forecast_period_ship_orig)),
                        ]),
                        _vm._v("  "),
                      ])
                    : _c("span"),
                  _vm._v(" " + _vm._s(item.fp_shp) + " "),
                ]
              },
            },
            {
              key: "item.order_point",
              fn: function ({ item }) {
                return [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.editedItemHTML(item, "order_point")
                      ),
                    },
                  }),
                ]
              },
            },
            {
              key: "item.order_num_qty",
              fn: function ({ item }) {
                return [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.editedItemHTML(item, "order_num_qty")
                      ),
                    },
                  }),
                ]
              },
            },
            {
              key: "item.order_price",
              fn: function ({ item }) {
                return [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.editedItemHTML(
                          item,
                          "order_price",
                          (x) => "\\" + Math.round(x).toLocaleString()
                        )
                      ),
                    },
                  }),
                ]
              },
            },
            {
              key: "item.order_num_calc",
              fn: function ({ item }) {
                return [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.editedItemHTML(item, "order_num_calc")
                      ),
                    },
                  }),
                ]
              },
            },
            _vm._l([0, 1, 2, 3, 4], function (i) {
              return {
                key: `item.custom${i}`,
                fn: function ({ item }) {
                  return [
                    item[`custom${i}`] !== -1
                      ? _c("span", { key: `item.custom${i}` }, [
                          _vm._v(_vm._s(item[`custom${i}`])),
                        ])
                      : _vm._e(),
                  ]
                },
              }
            }),
            {
              key: "top",
              fn: function ({ originalItemsLength }) {
                return [
                  _c("table-tool-bar", {
                    attrs: {
                      loadingSave: _vm.loadingSave,
                      loadingDownload: _vm.loadingDownload,
                      numFilteredItems: originalItemsLength,
                      numItems: _vm.rawItems.length,
                    },
                  }),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("row-edit-dialog", {
        ref: "dialogEdit",
        attrs: {
          selectedTableItem: _vm.selectedTableItem,
          currentOptionValue: _vm.currentOptionValue,
          isForceZero: _vm.isForceZero,
          results: _vm.results,
          duct: _vm.duct,
          manualValue: _vm.manualValue,
        },
        on: { confirm: _vm.updateEditedTableItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }