var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "ma-0" },
    [
      _vm.numFilteredItems == _vm.numItems
        ? _c(
            "v-col",
            {
              staticClass:
                "font-weight-regular text--secondary ml-1 itemAmount mt-4",
              attrs: { cols: "auto", md: "auto" },
            },
            [_vm._v(" フィルタを設定していません ")]
          )
        : _c(
            "v-col",
            {
              staticClass:
                "font-weight-regular text--secondary ml-1 itemAmount mt-4",
              attrs: { cols: "auto", md: "auto" },
            },
            [
              _vm._v(" フィルタ設定中："),
              _c("b", [_vm._v(_vm._s(_vm.numFilteredItems))]),
              _vm._v("件を表示しています "),
            ]
          ),
      _c(
        "v-col",
        { staticClass: "mx-auto text-right" },
        [
          _c(
            "v-btn",
            {
              staticClass: "ml-2",
              attrs: { loading: _vm.loadingSave },
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("save")
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-content-save")]), _vm._v(" 一時保存 ")],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-2",
              attrs: { color: "primary", loading: _vm.loadingDownload },
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("download")
                },
              },
            },
            [
              _c("v-icon", [_vm._v("mdi-download")]),
              _vm._v(" 発注データ出力 "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }