var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-radio-group",
    {
      model: {
        value: _vm.sortDirection,
        callback: function ($$v) {
          _vm.sortDirection = $$v
        },
        expression: "sortDirection",
      },
    },
    [
      _c("v-radio", {
        attrs: { label: "ソートしない", value: "noSort", color: "primary" },
      }),
      _c("v-radio", {
        attrs: { label: "昇順にソート", value: "ascend", color: "primary" },
      }),
      _c("v-radio", {
        attrs: { label: "降順にソート", value: "descend", color: "primary" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }