var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-3 ma-10", attrs: { tile: "" } },
    [
      _c("v-card-title", [_vm._v(" 発注チェック"), _c("br")]),
      _c(
        "v-row",
        { staticClass: "pl-4" },
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-select", {
                staticStyle: { width: "300px" },
                attrs: { items: _vm.forecastYWsSelectOptions },
                model: {
                  value: _vm.selectedYW,
                  callback: function ($$v) {
                    _vm.selectedYW = $$v
                  },
                  expression: "selectedYW",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-select", {
                staticStyle: { width: "300px" },
                attrs: { "return-object": "", items: _vm.forecastVersions },
                model: {
                  value: _vm.selectedVersion,
                  callback: function ($$v) {
                    _vm.selectedVersion = $$v
                  },
                  expression: "selectedVersion",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-card-text", [
        _vm._v(" 最新の需要予測結果から、要確認の商品一覧"),
        _vm.tableItems.length > 0
          ? _c("span", [
              _vm._v("(全 "),
              _c("b", [_vm._v(_vm._s(_vm.tableItems.length))]),
              _vm._v(" 件)"),
            ])
          : _vm._e(),
        _vm._v("を表示しています "),
      ]),
      _c("order-table", {
        attrs: {
          "raw-items": _vm.tableItems,
          loadingSave: _vm.loadingSave,
          loadingDownload: _vm.loadingDownload,
          loadedEdits: _vm.loadedTableEdits,
          results: _vm.results,
          duct: _vm.duct,
        },
        on: { save: _vm.saveEdits, download: _vm.downloadFile },
      }),
      _c("snackbar", { ref: "snackbar" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }