var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "mt-3 mb-4 px-3" },
    [
      _c("v-text-field", {
        attrs: {
          "hide-details": "",
          dense: "",
          solo: "",
          "prepend-inner-icon": "mdi-magnify",
        },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }