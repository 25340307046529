var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      key: _vm.header.text,
      attrs: {
        bottom: "",
        right: "",
        "offset-y": "",
        "min-width": "200",
        rounded: true,
        "close-on-content-click": false,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: onMenu, attrs: attrsMenu }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", disabled: !_vm.header.description },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: onTooltip, attrs: attrsTooltip }) {
                          return [
                            _c(
                              "th",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass:
                                      "text-no-wrap pa-4 tableHeader",
                                    attrs: { valign: "middle" },
                                  },
                                  "th",
                                  { ...attrsMenu, ...attrsTooltip },
                                  false
                                ),
                                { ...onMenu, ...onTooltip }
                              ),
                              [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.header.text),
                                  },
                                }),
                                _vm.filterEnabled
                                  ? _c("v-badge", {
                                      attrs: {
                                        bordered: "",
                                        color: "primary",
                                        "offset-y": "8",
                                        icon: "mdi-filter",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.sortPriority
                                  ? _c("v-badge", {
                                      attrs: {
                                        bordered: "",
                                        color: "#FF5252",
                                        "offset-y": "-10",
                                        content: _vm.sortPriority,
                                        icon: "mdi-icon",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm.header.description
                    ? _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.header.description) },
                      })
                    : _vm._e(),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-card",
        {
          staticClass: "overflow-y-auto",
          attrs: { dense: "", "max-height": "400" },
        },
        [
          _c(
            "v-card-text",
            [
              _vm.filterWindowComponent
                ? _c(
                    "div",
                    [
                      _c(_vm.filterWindowComponent, {
                        ref: _vm.filterRefName,
                        tag: "component",
                        attrs: {
                          header: _vm.header,
                          valueSet: _vm.valueSets[_vm.header.value] || null,
                          valueTexts: _vm.valueTexts[_vm.header.value] || null,
                        },
                      }),
                      _c("hr"),
                    ],
                    1
                  )
                : _vm._e(),
              _c("sort-menu", {
                ref: _vm.sortRefName,
                attrs: { header: _vm.header },
              }),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-left" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "red darken-1",
                          attrs: { dark: "" },
                          on: { click: _vm.resetRules },
                        },
                        [_vm._v("リセット")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.confirmRules },
                        },
                        [_vm._v("完了")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }