var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-data-table",
    _vm._b(
      {
        attrs: { items: _vm.displayedItems, headers: _vm.headers },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return _vm._l(_vm.headers, function (header) {
                  return _c("filterable-table-header", {
                    key: header.value,
                    ref: "header",
                    refInFor: true,
                    attrs: {
                      header: header,
                      valueSets: _vm.valueSets,
                      valueTexts: _vm.valueTexts,
                      sortPriority: _vm.sortPriority[header.value],
                      filterEnabled: header.value in _vm.filterRules,
                    },
                    on: { "update-rules": _vm.applyRules },
                  })
                })
              },
              proxy: true,
            },
            _vm._l(_vm.dataTableSlots, function (slot) {
              return {
                key: `${slot}`,
                fn: function (attrs) {
                  return [_vm._t(slot, null, null, attrs)]
                },
              }
            }),
          ],
          null,
          true
        ),
      },
      "v-data-table",
      _vm.dataTableProps,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }